import React, {useState, useEffect} from 'react'
import {Col, Container, Form, Row} from 'react-bootstrap'
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom'
import {downloadExcel, get_financial_year} from "../../helper/Api";
import moment from 'moment';

const ManageDisbursmentHeader = ({onDateChange, type}) => {
    // const defaultDateColumn = type === 'Depository' ? 'approval_date' : 'voucher_date';
    const [fy_list, set_fy_list] = useState([]);
    const {authData} = useSelector((state) => state.authData);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const [fy_id, setFyId] = useState(2);
    const [dateColumn, setDateColumn] = useState(type === 'Depository' ? 'approval_date' : 'voucher_date');
    const [searchPlaceholder, setPlaceHolder] = useState('Enter to search ...');
    

const fetchFinancialYears = async () => {
    const fy = await get_financial_year();
      if (fy.data.status) {
        set_fy_list(fy.data.list)
      }
}
    useEffect(() => {
    fetchFinancialYears();
    switch(type) {
        case 'Schemes':
            setPlaceHolder('Enter scheme code to search');
            break;
        case 'Budgets':
            setPlaceHolder('Enter scheme code to search');
            break;
        case 'Beneficiaries':
            setPlaceHolder('Enter Beneficiary Name, Contact Person, GST No, Pan');
            break;
        case 'OTHER':
            setPlaceHolder('Enter Beneficiary Name');
            break;
        case 'NPS':
            setPlaceHolder('Enter Beneficiary Name');
            break;
        case 'GIS':
            setPlaceHolder('Enter Beneficiary Name');
            break;
        case 'GST':
            setPlaceHolder('Enter Beneficiary Name');
            break;
        case 'Depository':
            setPlaceHolder('Enter Beneficiary Name or Description');
            break;
    }},[])

    const handleInput = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'fromDate') {
            setFromDate(value);
        } else if (name === 'dateColumn') {
            setDateColumn(value);
        } else if (name === 'search_str') {
            setToSearchStr(value);
        } else if (name === 'fy_id') {
            setFyId(value);
        } else {
            setToDate(value);
        }
    }

    const handleFilterClick = () => {
        if (onDateChange) {
            onDateChange(fromDate, toDate, dateColumn, searchStr, fy_id);
        }
    };
    const clearFilter = () => {
        setFromDate(''); // Clear fromDate
        setToDate('');
        setDateColumn('voucher_date');
        setToSearchStr('');
        setFyId(2);
        if (onDateChange) {
            onDateChange('', '', '', '', 2);
        }
    };

    const download_xml_as_zip_file = async () => {

        let apiURL = 'schemes/download_xml_as_zip';
        if (type === 'Schemes') {
            apiURL = 'schemes/download_excel';
        } else if (type == 'Budgets') {
            apiURL = 'schemes/download_excel_budget_list';
        } else if (type === 'Beneficiaries') {
            apiURL = 'beneficiary/download_beneficiary_excel';
        } else if (type === 'Invoices' || type === 'Disbursments') {
            apiURL = 'sanctions_order/download_excel_disbursment_invoice';
        }


        try {
            const response = await downloadExcel(apiURL, {
                from_date: fromDate,
                to_date: toDate,
                dateColumn,
                search_str: searchStr
            });
            if (response?.data?.status) {
                const aEle = document.createElement('a');
                aEle.href = response.data.excel_url;  // TODO Add complete File url here
                aEle.target = '_blank'; // Open the API URL in a new tab/window
                // document.body.appendChild(aEle);
                aEle.click();
                // document.body.removeChild(aEle);
            }


        } catch (e) {
            console.log('Something went wrong', e)
        }
    }

    return (
        <div className='addNewScheme w-95'>
            <div className="add_new_user">
                {/* <div className="d-flex"> */}
                    <Container fluid>
                        <Row>
                            <Col sm={4}>
                                {type ? <h4>{type}</h4> : <h4>Invoices</h4>}
                            </Col>
                        </Row>
                    </Container>
                {/* </div> */}

                <div>
                    {type === 'Schemes' || type == 'Budgets' || type === 'Beneficiaries' || type === 'Invoices' || type === 'Disbursments' ?
                        <button type="button" onClick={download_xml_as_zip_file}
                                className='btn btn-md btn-primary m-2'>Download
                            Excel
                        </button> : ''
                    }
                    {type !== 'OTHER' && type !== 'GST' && type !== 'NPS' && type !== 'GIS' && type !== 'TDS-IT' && type !== 'Schemes' && type !== "Budgets" && type !== "Beneficiaries" && type !== "Depository" && type !== "Interests"
                    && type !== "Accumulated Deduction" && type !== 'Budget Reappropriation' && type !== 'Beneficiary Reimbursement' && authData.user.user_type == 'l1' ?
                        <Link to="/add-sanction-order">
                            <button type="button" className="btn btn-primary">Add Payment Sanction order</button>
                        </Link> : ""
                    }

                    {type === 'Schemes' && authData.user.user_type === 'l1' ? <Link to="/add-scheme">
                        <button type="button" className="btn btn-primary">Add New Scheme</button>
                    </Link> : ""}
                    {type === 'Budgets' && authData.user.user_type === 'l1' ? <Link to="/add-budget">
                        <button type="button" className="btn btn-primary">Add Budget</button>
                    </Link> : ""}
                    {type === 'Budget Reappropriation' && authData.user.user_type === 'l1' ? <Link to="/add-budget-reappropriate">
                        <button type="button" className="btn btn-primary">Add Budget Reappropriation</button>
                    </Link> : ""}

                    {type === 'Beneficiaries' && authData.user.user_type == 'l1' ? <Link to="/add-beneficiary">
                        <button type="button" className="btn btn-primary">Add New Beneficiary</button>
                    </Link> : ""}
                </div>
            </div>
            <div className="add_new_user mt-2">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <h5>Filters</h5>
                        </Col>
                        {type !== 'Beneficiaries' && type !== 'Schemes' ?
                            <>
                            {type !== 'Budgets' && type !== 'OTHER' && type !== 'NPS' && type !== 'GIS' && type !== 'GST' && type !== 'TDS IT REPORT' ?
                            <Col sm={4}>
                                <Form.Group className="" controlId="">
                                    <select name="dateColumn" className='form-control mb-2' onChange={handleInput}>
                                        {type == 'Depository' ?
                                             <>    
                                             <option value="approval_date" selected={dateColumn === 'approval_date'}>Approval Date</option>
                                                 <option value="valid_from" selected={dateColumn === 'valid_from'}>Valid From</option>
                                                 <option value="valid_to" selected={dateColumn === 'valid_to'}>Valid To</option>
                                             </>
                                        : 
                                        type == 'Beneficiary Reimbursement' ? <>
                                            <option value="reimbursement_order_date" selected={dateColumn === 'reimbursement_order_date'}>Order Date</option>
                                            <option value="created_at" selected={dateColumn === 'created_at'}>Creation Date</option>
                                        </> :
                                        <> 
                                            <option value="voucher_date" selected={dateColumn === 'voucher_date'}>Voucher Date</option>
                                            <option value="invoice_date" selected={dateColumn === 'invoice_date'}>Invoice Date</option>
                                            <option value="sanction_order_date" selected={dateColumn === 'sanction_order_date'}>Payment Sanction Order Date</option>
                                            <option value="invoice_payment.added_on" selected={dateColumn === 'invoice_payment.added_on'}>Transaction Success Date</option> 
                                        </>
                                       
                                     }
                                        

                                    </select>
                                </Form.Group>
                            </Col>
                            : '' }
                            <Col sm={4}>
                            <Form.Group className="" controlId="">
                                <Form.Control type="date" name="fromDate"
                                                value={fromDate}
                                                onChange={handleInput}/>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="" controlId="">
                                    <Form.Control type="date" name="toDate"
                                                value={toDate}
                                                onChange={handleInput}/>
                                </Form.Group>
                            </Col>
                            </>
                            : ''
                        }
                        {/* fy year filter starts */}
                        {/* {
                            type == 'Schemes' ?
                            <Col sm={4}>
                                <Form.Group className="" controlId="">
                                    <select name="fy_id" className='form-control mb-2' onChange={handleInput}>
                                        <option value="">---select year---</option>
                                        {
                                        fy_list.map((fy) => {
                                            return (
                                                <option 
                                                value={fy.id}
                                                key={fy.id}
                                                selected={fy.id == fy_id ? true : false}
                                                >{fy.year}</option>
                                            );
                                        })
                                        }
                                    </select>
                                </Form.Group>
                            </Col> : ''
                        } */}
                        {/* fy year filter ends */}

                        {/* { type !== 'Beneficiaries' ? */}
                        <Col sm={4}>
                            <Form.Group controlId="">
                                <Form.Control type="text" name="search_str" onChange={handleInput} value={searchStr} placeholder={searchPlaceholder}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <div className="d-flex mt-3">
                                <div className="text-start mx-2">
                                    <button type="button" className="btn btn-outline-primary"
                                            onClick={clearFilter}>Clear
                                    </button>
                                </div>
                                <div className="text-start">
                                    <button type="button" className="btn btn-primary"
                                            onClick={handleFilterClick}>Filter
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default ManageDisbursmentHeader
