import React from 'react'
import './DashboardBox.css';
import {PiAlarmBold} from 'react-icons/pi'
const GridBox = ({icon=null,amount,title,caption,onClick,box_bg,icon_bg,fontSize = 24, subtitle, subtitleValue}) => {
  return (
    <div className='eac_grid' style={{backgroundColor:box_bg}} onClick={onClick}>
        <div className='amount_icon_wrapper'> 
            {icon ? 
              <div className='grid_icon' style={{backgroundColor:icon_bg}}>
                <span>{icon}</span>
            </div>
            :''}
            <span className='amountText' style={{
              fontSize: fontSize
            }}>{amount}</span>
        </div>
        <span className='grid_title'>{title}</span>
        {subtitle && <span className='grid_title'>{subtitle}: {subtitleValue}</span>}
        <span className='grid_caption'>{caption}</span>
    </div>
  )
}

export default GridBox
