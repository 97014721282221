import React, { useState, useEffect, useRef } from 'react'
import { read, utils } from 'xlsx';
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import {ADD_CHALLAN_FAIL, ADD_CHALLAN_START} from "../../RTK/Slices/ChallanSlice";
import {get_financial_year, 
    get_schemes, 
    get_single_beneficiary_reimbursement,
    add_beneficiary_reimbursement,
    add_beneficiary_reimbursement_beneficiaries, 
    update_beneficiary_reimbursement_approval,
    send_otp,
    verify_otp,
    update_beneficiary_reimbursement_payment
} from '../../helper/Api';
import {toast} from 'react-toastify';
import BeneficiaryTable from './BeneficiaryTable';
import ApproveReimbursementPreview from './ApproveReimbursementPreview';
import {numberToWords } from '../../helper/Utils'

export default function AddBeneficiaryReimbursement() {
    const dispatch = useDispatch();
    const {authData} = useSelector((state) => state.authData);
    const {id} = useParams();
    const [schemes, set_schemes] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [fy_list, set_fy_list] = useState([]);
    const [subheadsList, set_subheadsList] = useState([]);
    const totalAmount = useRef(0);
    const activeAddToSubhead = useRef(true);
    const navigate = useNavigate();
    const [showPreview, setShowPreview] = useState(false);
    const [init_payment,setInit_payment]=useState(0);
    const [otp,setOtp]=useState(null);
    const [fromSchemeDetails, setFromSchemeDetails] = useState({
        scheme_name: '',
        subhead_name: '',
        subhead_balance: '',
        reimbursementOrderDoc: '',
        reimbursementSupportDoc: ''
    });

    const [formData, setFormData] = useState({
        id: '',
        reimbursement_type: '',
        scheme_id: '',
        reimbursement_order_no: '',
        reimbursement_order_date: '',
        reimbursement_order_value: '',
        subhead_id: '',
        financial_year: '',
        beneficiary_list: [],
        attachment: [],
        support_doc: [],
        l1_remarks: '',
        l2_remarks: '',
        l3_remarks: '',
        l2_approval: '',
        l3_approval: '',
        l2_payment_approval: null,
        l3_payment_approval: null,
        gross_amount_total: 0,
        deduction_amount_total: 0,
        net_amount_total: 0,
        scheme_account: {},
        created_by: authData && authData.status ? authData.user.id : null
    });


    const fetch_list = async () => {
        const fy = await get_financial_year();
        // const st = await get_scheme_type();
        const schemes_list = await get_schemes();
        if (schemes_list.data.status) {
            set_schemes(schemes_list.data.schemes);
        } else {
            set_schemes([]);
        }
        if (fy.data.status) {
            set_fy_list(fy.data.list)
        }

        if(id) {
            const budgetData = await get_single_beneficiary_reimbursement(id);
            if(budgetData.data) {
                const data = budgetData.data.result;
                setFormData({
                    ...formData,
                    reimbursement_type: data.reimbursement_type,
                    scheme_id: data.scheme_id,
                    reimbursement_order_no: data.reimbursement_order_no,
                    reimbursement_order_date: data.reimbursement_order_date,
                    reimbursement_order_value: data.reimbursement_order_value,
                    subhead_id: data.subhead_id,
                    financial_year: data.financial_year_id,
                    l1_remarks: data.l1_remarks,
                    l2_remarks: data.l2_remarks,
                    l3_remarks: data.l3_remarks,
                    l2_approval: data.l2_approval,
                    l3_approval: data.l3_approval,
                    scheme_account: data.scheme_account[0],
                    l2_payment_approval: data.l2_payment_approval,
                    l3_payment_approval: data.l3_payment_approval,
                });
                setFromSchemeDetails(prevState => {
                    return {
                        ...prevState, scheme_name: data.name, 
                        reimbursementOrderDoc: data.reimbursement_order_url, 
                        reimbursementSupportDoc:data.reimbursement_support_doc_url 
                    }
                })
                handleSubheadData(data.scheme_id, schemes_list.data.schemes);
                const filter = schemes_list.data.schemes.find((scheme) => scheme.id == data.scheme_id);
                handleSubheadBalance(data.subhead_id, filter.sub_head_list);
                setBeneficiaries(data.beneficiaries);
            }
        }
        

    }

    const handleSubheadData = (value, schemesArr) => {
        if (value) {
            const filter = schemesArr.find((scheme) => scheme.id == value);
            if (filter) {
                setFromSchemeDetails(prevState => {
                    return {
                        ...prevState, scheme_name: filter.name,
                    }
                })
                set_subheadsList(filter.sub_head_list);
            } else {
                set_subheadsList([]);
            }
        } else {
            set_subheadsList([]);
        }
    }

    const handleSubheadBalance = (value, subheadsListArr) => {
        if (value) {
            const subhead = subheadsListArr.find(e => e.id == value);
            if(subhead) {
                setFromSchemeDetails(prevState => {
                    return {
                        ...prevState, 
                        subhead_name: subhead.name,
                        subhead_balance: subhead.balance
                    }
                })
            }
        }
    }

    const handleChallanFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [name]: value
        });
        if (name == 'reappropriation_amount') {
            if (totalAmount.current < value) {
                activeAddToSubhead.current = false;
            }
        }
        if (name == 'scheme_id') {
            handleSubheadData(value, schemes);
        }

        if(name == 'subhead_id') {
            handleSubheadBalance(value, subheadsList);
        }
    }

    const handleAttachmentChange = (event) => {
        const files = event.target.files;
        const key = event.target.name;
        if(key === 'attachment') {
            setFormData({
                ...formData,
                attachment: files
            });
        }else{
            setFormData({
                ...formData,
                support_doc: files
            });
        }
        
    };

    const handleSubmit = async (e) => {
        if (!id) {
            if (e && typeof e.preventDefault === 'function') {
                e.preventDefault();
            }
        }
        try {
            if (formData.reimbursement_order_no && 
                formData.reimbursement_order_date && 
                formData.reimbursement_order_value && 
                formData.subhead_id && 
                formData.scheme_id) {

                    if(fromSchemeDetails.subhead_balance < formData.reimbursement_order_value) {
                        toast.error('Reimbursement Order value greater then subhead balance', {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else if(formData.reimbursement_order_value < formData.gross_amount_total) {
                        toast.error('Gross amount greater then Reimbursement Order value', {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else {
                        const post_data = new FormData();
                        for (let i = 0; i < formData.attachment.length; i++) {
                            post_data.append('attachment[]', formData.attachment[i]);
                        }
                        for (let i = 0; i < formData.support_doc.length; i++) {
                            post_data.append('support_doc[]', formData.support_doc[i]);
                        }
                        post_data.append('scheme_id', formData.scheme_id);
                        post_data.append('reimbursement_order_no', formData.reimbursement_order_no);
                        post_data.append('reimbursement_order_date', formData.reimbursement_order_date);
                        post_data.append('reimbursement_order_value', formData.reimbursement_order_value);
                        post_data.append('subhead_id', formData.subhead_id);
                        post_data.append('financial_year_id', formData.financial_year_id);
                        post_data.append('reimbursement_type', formData.reimbursement_type);
                        post_data.append('created_by', formData.created_by);
                        post_data.append('l1_remarks', formData.l1_remarks);
                        dispatch(ADD_CHALLAN_START());
                        const response = await add_beneficiary_reimbursement(post_data);
                        let addDepositoryResponseData = response.data;
                        if (addDepositoryResponseData.status) {
                            await add_beneficiary_reimbursement_beneficiaries({beneficiaries: beneficiaries}, addDepositoryResponseData.result);
                            navigate('/beneficiary-reimbursement');
                        } else {
                            toast.error(addDepositoryResponseData.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            dispatch(ADD_CHALLAN_FAIL(addDepositoryResponseData.message));
                        }
                    }
            } else {
                toast.error('Please fill required fields.', {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } catch (error) {
            dispatch(ADD_CHALLAN_FAIL('error getting while updating challan'));
        }
    }

    const handleApprovalSubmit = async () => {
        try {
            dispatch(ADD_CHALLAN_START());
            const response = await update_beneficiary_reimbursement_approval(formData, id);
            let addDepositoryResponseData = response.data;
            if (addDepositoryResponseData.status) {
                navigate('/beneficiary-reimbursement');
            } else {
                toast.error(addDepositoryResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                dispatch(ADD_CHALLAN_FAIL(addDepositoryResponseData.message));
            }
        } catch (error) {
            dispatch(ADD_CHALLAN_FAIL('error getting while updating challan'));
        }
    }

    useEffect(() => {
        fetch_list();
    }, [])

    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    console.log('rows', rows);
                    const formattedArray = [];
                    rows.forEach(e => {
                        formattedArray.push({
                            name: e.Name,
                            mobile: e.Mobile,
                            email: e.Email,
                            address: e.Address,
                            ifsc: e.IFSC,
                            account_no: e['Account No'],
                            gross_amount: e['Gross Amount'],
                            deduction_amount: e['Deduction Amount'],
                            net_amount: e['Net Amount'],
                            created_by: formData.created_by
                        });
                        formData.deduction_amount_total += Number(e['Deduction Amount']);
                        formData.gross_amount_total += Number(e['Gross Amount']);
                        formData.net_amount_total += Number(e['Net Amount']);
                    });
                    setBeneficiaries(formattedArray)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const previewHandler = (e) => {
        e.preventDefault();
    };

    const handleApprove = (status) => {
        setFormData({
            ...formData,
            l2_approval: (authData.user.user_type == 'l2') ? status : formData.l2_approval,
            l3_approval: (authData.user.user_type == 'l3') ? status : formData.l3_approval,
        })
        setShowPreview(true);
    }

    const initiate_payment=async(e)=>{
        e.preventDefault();
        try {
            let mobile=authData.user.mobile;
            let otpIdentifier='beneficiary-reimbursement-payment-approval';
            if(mobile){
                let post_data={
                    mobile:mobile,
                    identifier:otpIdentifier
                };
               
                const {data}=await send_otp(post_data);
                
                if(data.status){
                    setInit_payment(1);
                    toast.success(data.message,{
                        position: toast.POSITION.TOP_CENTER
                    });
                }else{
                    toast.error(data.message,{
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        } catch (error) {
            toast.error("Code Error.Try Later !",{
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const handlePayNow=async()=>{
        try {
            let mobile=authData.user.mobile;
            let otpIdentifier='beneficiary-reimbursement-payment-approval';
            if(mobile && otp && otpIdentifier){
                let verify_data={
                    mobile:mobile,
                    identifier:otpIdentifier,
                    otp:otp
                };
                const {data}=await verify_otp(verify_data);
                if(data.status){
                    toast.success(data.message,{
                        position: toast.POSITION.TOP_CENTER
                    });    
                         
                    const response= await update_beneficiary_reimbursement_payment({
                        l2_payment_approval: authData.user.user_type == 'l2' ? 1 : formData.l2_payment_approval,
                        l3_payment_approval: authData.user.user_type == 'l3' ? 1 : formData.l3_payment_approval
                    }, id);
                    let response_data=response.data;
                    if(response_data.status){
                        toast.success(response_data.message,{
                            position: toast.POSITION.TOP_CENTER
                        });
                        setTimeout(function() {
                            window.location.href = "/beneficiary-reimbursement/payment";
                        }, 1000);             
                    }else{
                        toast.error(response_data.message,{
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                }else{
                    toast.error(data.message,{
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        } catch (error) {
            toast.error('Code Error. Try Later.',{
                position: toast.POSITION.TOP_CENTER
            });
        }      
    }

    


    return (
        <div>
            <ApproveReimbursementPreview 
            showPreview={showPreview} 
            setShowPreview={setShowPreview} 
            formData={formData} 
            fromSchemeDetails={fromSchemeDetails}
            handleSubmit={handleApprovalSubmit}
            fy_list={fy_list}/>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={previewHandler}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4> Add Beneficiary Reimbursement</h4>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label for="reimbursement_type" className="col-form-label">Reimbursement Type <span className="text-danger">*</span></label>
                                            <select className="form-control "
                                                    name='reimbursement_type'
                                                    required
                                                    value={formData.reimbursement_type}
                                                    onChange={handleChallanFormData}
                                                    >
                                                <option>Select Reimbursement Type</option>
                                                <option value="Recurring">Recurring</option>
                                                <option value="One-Time">One-Time</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label for="from_scheme_id" className="col-form-label">Scheme Code <span className="text-danger">*</span></label>
                                            <select className="form-control "
                                                    id="from_scheme_id" name='scheme_id'
                                                    required
                                                    value={formData.scheme_id}
                                                    onChange={handleChallanFormData}
                                                    >
                                                <option>Select Scheme</option>
                                                {
                                                    schemes.map((schemes) => {
                                                        return (
                                                            schemes.l2_status == 1 && schemes.l3_status == 1 &&
                                                            <option value={schemes.id}
                                                                    key={schemes.id}>{schemes.code}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Scheme Name</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.scheme_name} readonly />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <label for="inputFinancial" className="col-form-label"> Financial Year <span className="text-danger">*</span> :</label>
                                            <select className="form-control " id="financial_year"
                                                    name='financial_year_id'
                                                    onChange={handleChallanFormData}
                                                    required
                                            >
                                                <option value="">---select year---</option>
                                                {
                                                    fy_list.map((fy) => {
                                                        return (
                                                            <option value={fy.id}
                                                                    key={fy.id}
                                                                    selected={id && fy.id === formData.financial_year ? true : false}
                                                                    >{fy.year}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <label for="inputFinancial" className="col-form-label"> Subhead <span className="text-danger">*</span> :</label>
                                            <select className="form-control " id="financial_year"
                                                    name='subhead_id'
                                                    onChange={handleChallanFormData}
                                                    required>
                                                <option value="">---Select Subhead---</option>
                                                {
                                                    subheadsList.map((subhead) => {
                                                        return (
                                                            <option
                                                                value={subhead.id}
                                                                key={subhead.id}
                                                                selected={id && subhead.id === formData.subhead_id ? true : false}
                                                                >{subhead.code}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Subhead Balance</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.subhead_balance} readonly />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <hr />
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label for="" className="col-form-label">Reimbursement Order No. <span className="text-danger">*</span> :</label>
                                            <input type="text" 
                                            className="form-control"
                                            name='reimbursement_order_no'
                                            required
                                            value={formData.reimbursement_order_no}
                                            onChange={handleChallanFormData}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label for="" className="col-form-label">Reimbursement Order Date <span className="text-danger">*</span> :</label>
                                            <input type="date" 
                                            className="form-control"
                                            name='reimbursement_order_date'
                                            required
                                            value={formData.reimbursement_order_date}
                                            onChange={handleChallanFormData}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label for="" className="col-form-label">Reimbursement Order Value <span className="text-danger">*</span> :</label>
                                            <input type="text" 
                                            className="form-control"
                                            name='reimbursement_order_value'
                                            required
                                            value={formData.reimbursement_order_value}
                                            onChange={handleChallanFormData}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-group">
                                            <label className="input-group-btn my-0 mt-2 mr-2">
                                                Upload Reimbursement Order  <span className="text-danger">*</span>
                                            </label>
                                            <label className='p-1'>
                                                <input id="" type="file"
                                                        className='form-control'
                                                        onChange={handleAttachmentChange}
                                                        name='attachment'
                                                        accept=".pdf,.jpeg,.jpg,.png"
                                                        required
                                                        multiple/>
                                            </label>
                                            {
                                                fromSchemeDetails.reimbursementOrderDoc ? <a className="mt-2 mx-2" href={fromSchemeDetails.reimbursementOrderDoc}
                                                target='_blank'>Attachment</a> : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-group">
                                            <label className="input-group-btn my-0 mt-2 mr-2">
                                                Upload Reimbursement Supporting Doc
                                            </label>
                                            <label className='p-1'>
                                                <input id="" type="file"
                                                        className='form-control'
                                                        onChange={handleAttachmentChange}
                                                        name='support_doc'
                                                        accept=".pdf,.jpeg,.jpg,.png"
                                                        multiple/>
                                            </label>
                                            {
                                                fromSchemeDetails.reimbursementSupportDoc ? <a className="mt-2 mx-2" href={fromSchemeDetails.reimbursementSupportDoc}
                                                target='_blank'>Attachment</a> : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <hr />
                                    </div>
                                    {
                                        authData.user.user_type == 'l1' ?
                                        <>
                                        <div className="col-md-6">
                                        <a href="https://dev.uatesting.in/files/Beneficiary%20reimbursement.xlsx" className='btn btn-primary' target="_blank">Download Excel Format</a>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <label className="input-group-btn my-0 mt-2 mr-2">
                                                Upload Beneficiary Details
                                            </label>
                                            <label className='p-1'>
                                                <input id="upload_excel" type="file"
                                                        className='form-control'
                                                        onChange={handleImport}
                                                        accept=".xlsx"
                                                        multiple/>
                                            </label>
                                        </div>
                                    </div>
                                        </> : ''
                                    }
                                    

                                    <div className="col-md-12">
                                        <hr />
                                    </div>
                                    <div className="col-md-12">
                                        <BeneficiaryTable data={beneficiaries}/>

                                        <h6><strong>Total Gross Amount:</strong> {formData.gross_amount_total}</h6>
                                        <h6><strong>Total Deduction Amount:</strong> {formData.deduction_amount_total}</h6>
                                        <h6><strong>Total Net Amount:</strong> {formData.net_amount_total}</h6>

                                        <hr/>

                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="col-form-label">L1 Remarks</label>
                                                    <textarea 
                                                    className='form-control' 
                                                    name="l1_remarks"
                                                    readOnly={authData.user.user_type !== 'l1'}
                                                    onChange={handleChallanFormData}
                                                    value={formData.l1_remarks}
                                                    ></textarea>
                                                </div>
                                                
                                                {
                                                    authData.user.user_type == 'l2' ? <>
                                                    <div className="form-group">
                                                        <label className="col-form-label">L2 Remarks</label>
                                                        <textarea 
                                                        className='form-control' 
                                                        name="l2_remarks"
                                                        readOnly={authData.user.user_type !== 'l2'}
                                                        onChange={handleChallanFormData}
                                                        value={formData.l2_remarks}
                                                        ></textarea>
                                                    </div>
                                                    </> : ''
                                                }
                                                {
                                                    authData.user.user_type == 'l3' ? <>
                                                    <div className="form-group">
                                                        <label className="col-form-label">L3 Remarks</label>
                                                        <textarea 
                                                        className='form-control' 
                                                        name="l3_remarks"
                                                        readOnly={authData.user.user_type !== 'l3'}
                                                        onChange={handleChallanFormData}
                                                        value={formData.l3_remarks}
                                                        ></textarea>
                                                    </div>
                                                    </> : ''
                                                }
                                            </div>
                                            <div className='col-md-6 py-3'>
                                                {
                                                    (authData.user.user_type == 'l2' && !formData.l2_payment_approval) || (authData.user.user_type == 'l3' && !formData.l3_payment_approval) ?
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <h6><strong>Account Name: </strong>{formData.scheme_account.account_name}</h6>
                                                                <h6><strong>Bank Name: </strong>{formData.scheme_account.bank_name}</h6>
                                                                <h6><strong>Branch: </strong>{formData.scheme_account.branch_name}</h6>
                                                                <h6><strong>Account Type: </strong>{formData.scheme_account.account_type}</h6>
                                                                <h6><strong>Account No: </strong>{formData.scheme_account.account_no}</h6>
                                                                <h6><strong>IFSC: </strong>{formData.scheme_account.ifsc_code}</h6>
                                                                <h6><strong>Total Amount To Pay: </strong>{formData.reimbursement_order_value}</h6>
                                                                <h6><strong>Amount in Words: </strong>{numberToWords(formData.reimbursement_order_value)}</h6>
                                                            </div>
                                                        </div>
                                                        <button type="button" className='btn btn-primary me-lg-2' onClick={initiate_payment}> Initiate Payment</button>
                                                        <hr/>
                                                        <input type="number" className='form-control my-2' placeholder='Enter Otp' onChange={(e)=>setOtp(e.target.value)} value={otp} disabled={!init_payment}/>
                                                        <button type="button" className='btn btn-success' disabled={otp ? false: true} onClick={handlePayNow} > Approve Payment</button>
                                                    </> : ''
                                                }

                                                {
                                                    (authData.user.user_type == 'l2' && !formData.l2_approval) || (authData.user.user_type == 'l3' && !formData.l3_approval) ?
                                                    <>
                                                        <button type="button" className='btn btn-success me-lg-2' onClick={() => handleApprove(1)}> Approve</button>
                                                        <button type="button" className='btn btn-danger' onClick={() => handleApprove(0)}> Reject</button>
                                                    </> : ''
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-2 text-center">
                                            {
                                                !id ? <Button type="submit" variant="primary" onClick={handleSubmit}> Save Budget Reimbursement</Button> : ''
                                            } 
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}