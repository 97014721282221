import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import './BudgetAnalysis.css'
import AddInterestModal from '../Modal/AddInterestModal';
import { add_account_balance_interest, get_account_balance_interest, get_financial_year, update_account_balance_interest } from '../../helper/Api';
import { toast } from 'react-toastify';
import moment from 'moment';

const BudgetAnalysis = ({handleYearDropdown, isShowPopup, previewBankAccountData, handlePopUpState}) => {
  const {authData}=useSelector((state)=>state.authData);
  const [showPreview,setShowPreview]=useState(isShowPopup);
  const [formData,setFormData]=useState({
    "id": "",
    "account_name": "",
    "bank_name": "",
    "branch_name": "",
    "ifsc_code": "",
    "account_type": "",
    "account_no":"",
    "account_balance":"",
    "balance_date":"",
    "interest":"",
    "interest_date":"",
    "l2_approval": "",
    "l3_approval": "",
    "l2_remark": "",
    "l3_remark": "",
    'added_by':authData.user.id
  });
  const [viewData,setViewData]=useState({
    "account_name":"",
    "bank_name": "",
    "branch_name": "",
    "ifsc_code": "",
    "account_type": "",
    "account_no":"",
    "account_balance":"",
    "balance_date":"",
    "interest":"",
    "interest_date":"",
  });
  const [fy_list, set_fy_list] = useState([]);

  const interest_modal=(e)=>{
    e.preventDefault();
    
    setFormData({
      "account_name": "",
      "bank_name": "",
      "branch_name": "",
      "ifsc_code": "",
      "account_type": "",
      "account_no":"",
      "account_balance":"",
      "balance_date":"",
      "interest":"",
      "interest_date":"",
      'added_by':authData.user.id
    });
    setShowPreview(true);
  }

  const get_balance_interest=async()=>{
    try {
      const {data} = await get_account_balance_interest();
      const fy = await get_financial_year();
      if (fy.data.status) {
        set_fy_list(fy.data.list)
      }
      if(data.status){
        // setFormData(data.data)
        // setViewData(data.data)
      }
    } catch (error) {
      
    }
  }
  useEffect(() => {
    get_balance_interest();
  }, [])

  useEffect(() => {
    setShowPreview(isShowPopup);
    setFormData(previewBankAccountData)
  }, [isShowPopup])

  const handleSubmit=async(e)=>{
    e.preventDefault();
    // console.log(authData.user)
    try {
      let res;
      if(isShowPopup) {
        formData.l2_approval = null;
        formData.l3_approval = null;
         const {data} = await update_account_balance_interest(formData, previewBankAccountData.id);
         handlePopUpState(false);
        res = data;
      } else {
        const {data} =await add_account_balance_interest(formData);
        res = data;
      }
      // console.log('res', res);
      if(res.status){
        setShowPreview(false);
        toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER
        });
        get_balance_interest();
      }else{
        toast.error(res.message, {
            position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.log(error)
      toast.error("System Error. Try Later!", {
          position: toast.POSITION.TOP_CENTER
      });
    }
  }

  return (
    <div className="budget_analysis">
      <AddInterestModal showPreview={showPreview} setShowPreview={setShowPreview} formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} handlePopUp={handlePopUpState}></AddInterestModal>
        <h1>Dashboard - Financial Year 2024-2025 <span style={{
          fontSize: '16px', fontStyle: 'italic', color: '#da4d42'
        }}>(All figures in indian rupees)</span></h1>
        <div className="budget_date_wrapper">
        {authData.user.user_type==='l1'?(
          <button type="button" className="btn btn-primary" style={{float: 'right', marginLeft: '10px'}} onClick={interest_modal}>Add Scheme Bank Account</button>
        ):""}
          <select 
          className="form-control" 
          id="financial_year"
          name='financial_year_id'
          style={{
            width: '250px',
            float: 'right'
          }}
          onChange={handleYearDropdown}
          >
            <option value="">---select year---</option>
            {
              fy_list.map((fy) => {
                  return (
                      <option 
                      value={fy.id}
                      key={fy.id}
                      selected={fy.start_year === moment().format('YYYY') ? true : false}
                      >{fy.year}</option>
                  );
              })
            }
        </select>
        
        </div>
    </div>
  )
}

export default BudgetAnalysis